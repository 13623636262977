/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  CanMatchFn,
  Route,
} from '@angular/router';
import { PROFILES } from '@carwash-project/modules/data-access/common';
import { UiNotificationsService } from '@carwash-project/modules/ui';
import { Store } from '@ngxs/store';
import { AuthActions, AuthState } from '../state';

const checkProfileUserLogin = (routerName: string): boolean => {
  const store = inject(Store);
  const notification = inject(UiNotificationsService);
  const currentProfileID = store.selectSnapshot(AuthState.profileUser);
  const modulesAccess = store.selectSnapshot(AuthState.access);
  const canAccessPanel = store.selectSnapshot(AuthState.canAccessPanel);
  const modulesAccessFilter = modulesAccess
    ?.filter((item) => item.canAccess)
    .map((item) => item.name);
  const modulesAccessClient = ['Dashboard-client'];

  if (!currentProfileID) {
    return false;
  }

  if (
    modulesAccessClient.includes(routerName) &&
    PROFILES.Cliente == currentProfileID
  ) {
    return true;
  }

  if (
    canAccessPanel &&
    modulesAccessFilter?.length &&
    modulesAccessFilter.includes(routerName)
  ) {
    return true;
  }

  if (!canAccessPanel) {
    notification.error(
      'Accesso denegado',
      'El usuario no pose acceso al panel'
    );
    store.dispatch(new AuthActions.Logout());
    return false;
  }

  return false;
};

export const profileGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const { name } = route.data;

  return checkProfileUserLogin(name);
};

export const profileMatchGuard: CanMatchFn = (route: Route) => {
  const { name } = route.data as {
    [key: string]: any;
  };
  return checkProfileUserLogin(name);
};
