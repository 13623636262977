import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../state';

const checkPublicAuthStatus = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const store = inject(Store);
  const router = inject(Router);
  const isAuthenticated = store.selectSnapshot(AuthState.isAuthenticated);
  const profile = store.selectSnapshot(AuthState.profileUser);

  if (isAuthenticated) {
    const routerUser = profile == 1 ? 'dashboard-user' : 'dashboard';
    router.navigate(['main', routerUser]);

    return false;
  }

  return true;
};

export const publicActivateGuard: CanMatchFn = () => {
  return checkPublicAuthStatus();
};

export const publicMatchGuard: CanMatchFn = () => {
  return checkPublicAuthStatus();
};
