import { inject } from '@angular/core';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthState } from '../state';

const checkAuthStatus = ():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {
  const store = inject(Store);
  const isAuthenticated = store.selectSnapshot(AuthState.isAuthenticated);
  const router = inject(Router);

  if (!isAuthenticated) {
    router.navigate(['login']);
  }
  return isAuthenticated;
};

export const privateActivateGuard: CanMatchFn = () => {
  return checkAuthStatus();
};

export const privateMatchGuard: CanMatchFn = () => {
  return checkAuthStatus();
};
